<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="roleData === undefined"
    >
      <h4 class="alert-heading">
        خطأ في جلب البيانات
      </h4>
      <div class="alert-body">
        لم يتم العثور على طلب مع معرف الطلب هذا. راجع
        <b-link
          class="alert-link"
          :to="{ name: 'roles' }"
        >
          قائمة الطلبات
        </b-link>
        لطلبات أخرى.
      </div>
    </b-alert>

    <template v-if="roleData">
      <b-row>
        <b-col
          cols="12"
          xl="12"
          lg="12"
          md="12"
        >
          <role-view-role-info-card :role-data="roleData" />
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import store from '@/store';
import router from '@/router';
import { ref, onUnmounted } from '@vue/composition-api';
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue';
import roleStoreModule from '../roleStoreModule';
import RoleViewRoleInfoCard from './RoleViewRoleInfoCard.vue';

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    RoleViewRoleInfoCard,
  },
  setup() {
    const roleData = ref(null);

    const ROLE_APP_STORE_MODULE_NAME = 'app-role';

    // Register module
    if (!store.hasModule(ROLE_APP_STORE_MODULE_NAME)) store.registerModule(ROLE_APP_STORE_MODULE_NAME, roleStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ROLE_APP_STORE_MODULE_NAME)) store.unregisterModule(ROLE_APP_STORE_MODULE_NAME);
    });

    store
      .dispatch('app-role/fetchRole', {
        id: router.currentRoute.params.id,
      })
      .then((response) => {
        const { role } = response.data;
        roleData.value = response.data;
        router.currentRoute.meta.breadcrumb = [
          {
            text: 'الرئيسية',
            to: '/',
          },
          {
            text: 'الأدوار',
            to: '/roles',
          },
          {
            text: role.name_arabic,
            active: true,
          },
        ];
      })
      .catch((error) => {
        if (error.response.status === 404) {
          roleData.value = undefined;
        }
      });

    return {
      roleData,
    };
  },
};
</script>

<style>
</style>
